html {
    scroll-behavior: smooth;
}

body {
    font-family: "Lidl Font Pro", sans-serif;
    margin: 0;
    -webkit-font-smoothing: auto;
    background-color: #fff;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
menu,
nav,
section,
summary {
    display: block;
}

h1 {
    font-size: 8rem;
}

h2 {
    font-family: "Lidl Font Pro";
    font-size: 72px;
    font-weight: 800;
    line-height: 74px;
}

h3 {
    font-family: "Lidl Font Pro";
    font-size: 46px;
    font-weight: 600;
    line-height: 50px;
}

h4 {
    font-family: "Lidl Font Pro";
    font-size: 30px;
    font-weight: 800;
    line-height: 34px;
}

h5 {
    font-family: "Lidl Font Pro";
    font-size: 24px;
    font-weight: 800;
}

p,
label {
    font-size: 1.1rem;
    font-weight: normal;
    line-height: 1.5rem;
}

.lead {
    font-size: 1.2rem;
    font-weight: 600;
    line-height: 1.5rem;
}

.nopadding {
    padding: 0 !important;
    margin: 0 !important;
}

#wrapper {
    perspective: 5px;
    perspective-origin: center top;
    position: relative;
    height: 100vh;
    overflow-y: scroll;
    overflow-x: hidden;
}

header {
    height: 1000px;
    max-width: 100%;
    background-image: url("../assets/images/tavling_desktop.png");
    background-size: cover;
    /* (perspective — distance) / perspective = scaleFactor */
    transform: translateZ(-2px) scale(calc(7 / 5));
    transform-origin: 50% top;
    z-index: 1;
    margin-top: -20px;
}

header > .container {
    justify-content: center;
    display: flex;
    padding-top: 50px;
}

#mobile-navigation-wrapper {
    display: none;
}

.carousel-control-prev,
.carousel-control-next {
    top: 20%;
    bottom: 30%;
}

section {
    position: relative;
    padding-top: 60px;
    padding-bottom: 60px;
}

footer {
    position: relative;
}

iframe {
    display: block; /* iframes are inline by default */
    border: none; /* Reset default border */
    overflow: hidden;
}

iframe.small {
    /* Reset default border */
    height: 20vh; /* Viewport-relative units */
    width: 20vw;
}

.read-more::after {
    content: url(../assets/images/icon-down.svg);
    display: inline-block;
    transition: all 0.5s;
}

.show + .read-more::after {
    content: url(../assets/images/icon-up.svg);
    display: inline-block;
    transition: all 0.5s;
}

.navbar .container-fluid {
    justify-content: flex-end;
}

.nav {
    padding-top: 40px;
}

.nav-link {
    font-size: 1.5em;
    color: white;
}

a.nav-link:hover {
    color: #fff000;
}

.full-bg-image {
    position: relative;
    padding-top: 0;
    padding-bottom: 0;
    width: 100%;
    height: 1200px;
    background-size: cover;
    background-position: center;
}

img {
    object-fit: cover;
    max-width: 100%;
    height: auto;
}

img.logo {
    position: absolute;
    width: 130px;
    object-fit: cover;
    top: 40px;
    left: 40px;
    margin-top: 0 !important;
}
.navigation-wrapper {
    display: flex;
    justify-content: center;
    padding-top: 60px;
}

#lidl-hero-text {
    position: absolute;
    top: 50%; /* position the top  edge of the element at the middle of the parent */
    left: 25%; /* position the left edge of the element at the middle of the parent */
    transform: translate(-50%, -50%);
    width: 50%;
}

#countdown span {
    display: inline-block;
    width: auto;
    font-size: 1.5rem;
}

#competition-teaser {
    padding-top: 40px;
    padding-bottom: 0;
}
#competition-teaser .container {
    padding-right: 0;
    padding-left: 0;
}

#video-grid {
    display: grid;
    grid-gap: 1rem;
    grid-row-gap: 1rem;
    row-gap: 1rem;
    padding-left: 0;
    grid-template-columns: repeat(4, 24%);
}

#video-grid > div {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    color: white;
}

#video-grid > div > p {
    margin-top: 10px;
}

#competition-teaser-carousel .carousel-indicators [data-bs-target] {
    background-color: #68a5e9;
}

.carousel-indicators {
    position: initial;
    z-index: 2;
    display: flex;
    justify-content: center;
    padding: 0;
    margin-top: 1rem;
    margin-right: 15%;
    margin-bottom: 1rem;
    margin-left: 15%;
    list-style: none;
}

.carousel-indicators [data-bs-target] {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 10px;
    height: 10px;
    padding: 0;
    margin-right: 15px;
    margin-left: 15px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff000;
    background-clip: padding-box;
    border: 1px solid transparent;
    border-radius: 5px;
    /* border-top: 10px solid transparent;
    border-bottom: 10px solid transparent; */
    opacity: 0.5;
    transition: opacity 0.6s ease;
}

.carousel-indicators button.active {
    opacity: 1;
}

.carousel-control-next-icon,
.carousel-control-prev-icon {
    width: 2.5rem;
    height: 2.5rem;
}

.half-section-image {
    position: absolute;
    top: 0;
    left: 0;
    right: 50%;
    height: 1500px;
}

.half-section-image img {
    height: inherit;
}

video {
    background: #fff;
    background-color: #fff;
}

#message-from-management-image-small {
    display: none;
}

#lidl-johan-image {
    height: 500px;
    object-position: 50% 20%;
}

.centered {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    text-align: center;
}

.outline-button {
    font-size: 16px;
    font-weight: 800;
    display: inline-block;
    width: 200px;
    padding: 13px;
    padding-right: 20px;
    padding-left: 20px;
    cursor: pointer;
    transition: all 0.2s ease-out;
    color: #2777d1 !important;
    border-width: 2px;
    border-style: solid;
    border-color: #faeb63;
    border-radius: 30px;
    background: #faeb63;
    text-decoration: none;
}

.outline-button:hover {
    transition: all 0.2s ease-in;
    color: #fff !important;
    border-color: #faeb63;
    background: #2777d1;
}

@media (max-width: 1200px) {
    img.logo {
        width: 110px;
    }

    #lidl-johan-image {
        height: 500px;
        object-position: 50% 20%;
    }
}

@media (max-width: 992px) {
    header {
        height: 75%;
        background-image: url("../assets/images/tavling_mobile.png");
        background-position: center;
        background-repeat: no-repeat;
    }

    img.logo {
        width: 90px;
    }

    .half-section-image {
        height: 1000px;
    }
}

@media (max-width: 768px) {
    header {
        height: 75%;
        background-image: url("../assets/images/tavling_mobile.png");
        background-position: center;
        background-repeat: no-repeat;
    }
    #lidl-johan-image {
        height: 100%;
        object-position: top;
    }
    #countdown span {
        display: inline-block;
        width: auto;
        font-size: 1rem;
    }

    #mobile-navigation-wrapper {
        display: block;
        position: absolute;
        top: 40px;
        right: -20px;
    }

    .centered {
        position: static;
        left: 0;
        right: 0;
        top: 100%;
        transform: none;
        width: inherit;
        width: 100%;
        padding-right: 16px;
        padding-left: 16px;
        padding-bottom: 16px;
        text-align: center;
    }

    #lidl-teasers img {
        height: auto;
    }

    #lidl-teasers img.half-width {
        width: 100%;
    }

    .full-width {
        width: 85%;
    }

    .half-section-image {
        display: none;
    }

    .navigation-wrapper {
        display: flex;
        justify-content: flex-end;
        padding-top: 0;
    }

    .navbar {
        margin-right: 20px;
    }
    .navbar-nav {
        background: #2777d1;
        align-items: flex-end;
        -webkit-align-items: flex-end;
        padding-right: 20px;
        padding-left: 20px;
        margin-top: 10px;
        margin-right: 20px;
        border-radius: 5px;
    }

    .navbar-collapse {
        /* flex-basis: 100%; */
        flex-grow: 0;
        align-items: end;
    }

    .nav-link {
        font-size: 1.25em;
        font-weight: 800;
        color: #fff000;
    }

    section {
        padding-top: 40px;
        padding-bottom: 40px;
    }

    img.logo {
        width: 70px;
        position: relative;
        top: 40px;
        left: 40px;
    }

    #song-lyrics {
        font-style: italic;
    }

    #video-grid {
        grid-template-columns: repeat(2, 45%);
    }

    .full-bg-image {
        width: 100%;
        height: 600px;
    }

    #download-lidl-song .full-width {
        width: 75%;
    }
}

@media (max-width: 576px) {
    img.logo {
        width: 70px;
        position: relative;
        top: 40px;
        left: 20px;
    }
    /* 
    .lead {
        font-size: 1rem;
        font-weight: 600;
        font-weight: normal;
        line-height: 1.4rem;
    } */

    #faq-icon {
        display: none;
    }

    #video-grid {
        grid-template-columns: repeat(1, 100%);
    }

    #competition #image-container {
        display: none;
    }

    #lidl-hero-text {
        width: 75%;
        left: 50%;
    }

    .carousel-indicators [data-bs-target] {
        margin-right: 10px;
        margin-left: 10px;
    }
}

.color-white {
    color: #fff;
}

.color-yellow {
    color: #fff000;
}

.color-light-blue {
    color: #68a5e9;
}

.color-dark-blue {
    color: #2777d1;
}

.bg-yellow {
    background-color: #fff000;
    background: #fff000;
}

.bg-light-blue {
    background-color: #68a5e9;
    background: #68a5e9;
}

.bg-dark-blue {
    background-color: #2777d1;
    background: #2777d1;
}

.bg-dark {
    background-color: #2777d1;
    background: #2777d1;
}

.bg-white {
    background-color: #fff;
    background: #fff;
}

.competition-text {
    font-size: 30px;
    font-weight: 800;
    line-height: 34px;
}

.full-width {
    width: 100%;
}

.half-width {
    width: 50%;
}
