.input-field {
    font-size: 16px;
    font-weight: 800;
    display: inline-block;
    width: 300px;
    padding: 13px;
    padding-right: 20px;
    padding-left: 20px;
    cursor: pointer;
    transition: all 0.2s ease-out;
    color: #faeb63 !important;
    border-width: 2px;
    border-style: solid;
    border-color: #faeb63;
    border-radius: 30px;
    background: #68a5e9;
    text-decoration: none;
}

.input-field::focus {
    border-color: green;
}

.input-field::placeholder {
    color: #faeb63;
}

.outline-button {
    font-size: 16px;
    font-weight: 800;
    display: inline-block;
    width: 300px;
    padding: 13px;
    padding-right: 20px;
    padding-left: 20px;
    cursor: pointer;
    transition: all 0.2s ease-out;
    color: #68a5e9 !important;
    border-width: 2px;
    border-style: solid;
    border-color: #faeb63;
    border-radius: 30px;
    background: #faeb63;
    text-decoration: none;
    text-align: center;
}

#submit-video-button {
    width: auto;
}

.competition-image {
    width: 80%;
}

@media (max-width: 768px) {
    .competition-image {
        width: 50%;
        display: flex;
        margin: 0 auto;
    }
}

.step {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 400px;
    opacity: 1;
}

.step > p {
    font-weight: bold;
}

.step-hidden {
    display: none;
}

/* ensure step is displayed block during the transition  */
.step-transition.step-hidden {
    display: block;
    opacity: 0;
}

.step-transition {
    transition: opacity 1s ease;
}

.form-group {
    margin-bottom: 1rem;
}
.inputfile {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
}

.inputfile + label {
    display: inline-block;
    cursor: pointer;
}

input:-internal-autofill-selected {
    appearance: menulist-button;
    background-image: none !important;
    background-color: #68a5e9 !important;
    color: #faeb63 !important;
}

.video-grid-label {
    color: #fff;
    font-size: 1.5rem;
}

.hidden {
    display: none;
}

.outline-button:hover {
    transition: all 0.2s ease-in;
    color: #fff !important;
    border-color: #faeb63;
    background: #2777d1;
}

.animated-progress {
    width: 300px;
    height: 50px;
    border-radius: 10px;
    margin: 30px 10px 0 10px;
    border: 2px solid #faeb63;
    overflow: hidden;
    position: relative;
}

.animated-progress span {
    height: 100%;
    display: block;
    width: 0;
    color: rgb(255, 251, 251);
    line-height: 30px;
    position: absolute;
    text-align: end;
    background-color: #faeb63;
    padding-right: 5px;
}

@keyframes FadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes FadeOut {
    0% {
        opacity: 1;
        height: auto;
    }
    100% {
        opacity: 0;
        height: 0;
    }
}

.fade-in {
    animation-name: FadeIn;
    animation-duration: 1s;
    transition-timing-function: ease-out;
}

@-moz-keyframes FadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-webkit-keyframes FadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-o-keyframes FadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-ms-keyframes FadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.fade-out {
    animation-name: FadeOut;
    animation-duration: 0.5s;
    transition-timing-function: ease-out;
}
